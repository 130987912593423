const dateService = {
    getUnixTimestampsFromTo (selectedDates: string) {
        //"24.02.2021 12:00 to 26.02.2021 12:00"
        console.log(selectedDates);
        const startDate = selectedDates.slice(0,selectedDates.indexOf(" to "));
        const untilDate = selectedDates.slice(selectedDates.indexOf(" to ") + 4,selectedDates.length);
        if (startDate && untilDate) {
            const from = this.getFormattedDate(startDate);
            const to =  this.getFormattedDate(untilDate);
            const fromDate = Math.floor(from.getTime() / 1000);
            const dueDate = Math.floor(to.getTime() / 1000);
            return [ fromDate, dueDate ]
        }
        else return [0,0]
    },
    pad(value: string|number): string {
        if (Number(value)<10)
            return '0' + Number(value)
        else return ''+ value
    },
    getRangeStringFromTo(selectedDates: string[]) {

        const from = new Date(selectedDates[0])
        const to = new Date(selectedDates[1])
        const range =  this.pad(from.getDate())+'.'+this.pad(from.getMonth()+1) +'.'+ from.getFullYear()+' '+ this.pad(from.getHours()) + ':' + this.pad(from.getMinutes())
            +' - '+ this.pad(to.getDate())+'.'+this.pad(to.getMonth()+1) +'.'+ to.getFullYear() +' '+ this.pad(to.getHours()) + ':' + this.pad(to.getMinutes())
        return range;
    },
    getRangeStringFromToWithTo(selectedDates: string[]) {

        const from = new Date(selectedDates[0])
        const to = new Date(selectedDates[1])
        const range =  this.pad(from.getDate())+'.'+this.pad(from.getMonth()+1) +'.'+ from.getFullYear()+' '+ this.pad(from.getHours()) + ':' + this.pad(from.getMinutes())
            +' to '+ this.pad(to.getDate())+'.'+this.pad(to.getMonth()+1) +'.'+ to.getFullYear() +' '+ this.pad(to.getHours()) + ':' + this.pad(to.getMinutes())
        return range;
    },
    getFromUnixTimestamp(dateRange: string) {
        debugger
        // const datesService = dateRange.split(" to ");
        // const from = new Date(datesService[0]);
        // return Math.round(from.getTime() / 1000);

        return this.getUnixTimestampsFromTo(dateRange)[0];
    },
    getToUnixTimestamp(dateRange: string) {
        debugger
        // const datesService = dateRange.split(" to ");
        // const from = new Date(datesService[1]);
        // return Math.round(from.getTime() / 1000);

        return this.getUnixTimestampsFromTo(dateRange)[1];
    },
    getFormattedDate(date: string) {
        const dt1 = parseInt(date.substring(0, 2));
        const mon1 = parseInt(date.substring(3, 5));
        const yr1 = parseInt(date.substring(6, 10));
        const hours = parseInt(date.substring(12, 14));
        const minute = parseInt(date.substring(15, 17));
        const from = new Date(yr1, mon1 - 1, dt1, hours, minute, 0);
        return from;
    }
}

export default dateService;
