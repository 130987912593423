<template>
   <avatar :size="25"  :fullname="worker.name" :radius="25" :image="worker.avatarLink" :title="worker.name" style="cursor: grabbing"/>
</template>

<script>
import Avatar from 'vue-avatar-component';

export default {
  name: "Worker",
  components: { Avatar },
  props: {
    worker: {
      type: Object,
      required: true
    }
  }
}
</script>

<style scoped>

</style>