<template>
  <div class="add-ticket-form container">
    <div class="row" style="margin-top: 50px;">
      <div class="col-lg-12">
        <div class="card-custom">
          <form id="ticket-create-form" @submit.prevent="UpdateTicket"  enctype="multipart/form-data">
            <div class="header-form"><h3>Ticket: {{number}}</h3></div>
            <div class="modal-body">
              <div class="panel-info">
                <div class="panel-body" style="margin-top: 10px">
                  <div class="row">
                    <div class="col-md-6">
                      <div class="form-group field-threadform-title">
                        <label class="control-label" data-custom-for="threadform-title">Short title</label>
                        <input type="text" data-custom-id="threadform-title" class="form-control" name="ThreadForm[title]" v-model="title">
<!--                        <p class="help-block help-block-error"></p>-->
                      </div>
                      <div class="form-group field-ticketform-highlight">
                        <label class="control-label" data-custom-for="ticketform-highlight">Highlights</label>
                        <textarea data-custom-id="ticketform-highlight" class="form-control" v-model="highLight" rows="1"></textarea>
                        <p class="help-block help-block-error"></p>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group field-threadform-body">
                        <label class="control-label" data-custom-for="threadform-body">Subject and problem description</label>
                        <textarea data-custom-id="threadform-body" class="form-control" v-model="body" rows="4"></textarea>
                        <p class="help-block help-block-error"></p>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-4">
                      <div class="form-group field-ticketform-daterange">
                        <label class="control-label" data-custom-for="ticketform-daterange">Time for processing</label>
                        <div>
                          <flat-pickr v-model="dateRange"
                                      :config="configCalendar"
                                      class="form-control"
                                      style="background-color: transparent"
                                      placeholder="Select Date & Time"
                                      @change="setDates">
                          </flat-pickr>
                        </div>
                        <p class="help-block help-block-error"></p>
                      </div>
                    </div>

                    <div class="col-md-4">
                      <label>Apartment   </label>
                      <Multiselect
                          v-model='apartmentId'
                          placeholder='Select object...'
                          label='name'
                          trackBy='name'
                          valueProp="id"
                          disabled='true'
                          :searchable='true'
                          @select='fetchPrices'
                          :options='apartments'
                      >
                      </Multiselect>
                      <p class="help-block help-block-error"></p>
                    </div>
                    <div class="col-md-4">
                      <label class="control-label" data-custom-for="ticketform-help_topic_id">Type of service layout</label>
                      <Multiselect
                          v-model='helpTopicId'
                          placeholder='Select type of service...'
                          label='name'
                          trackBy='name'
                          valueProp='id'
                          :searchable='true'
                          :options='topics'
                          @select='fetchPrices'
                      >
                      </Multiselect>
                      <p class="help-block help-block-error"></p>
                    </div>
                  </div>

                  <!--                  </div>-->
                  <!--                </div>-->
                  <!--              </div>-->
                  <hr/>
                  <div class="panel-info">
                    <div class="row"></div>
                    <!--                <div class="panel-heading"><h5>-->
                    <!--                  <a @click="openMapsOptions">Maps options</a>-->
                    <!--                </h5></div>-->
                    <!--                <div class="panel-body" data-custom-id="map-details" :style="addMapsOptions">-->
                    <div class="panel-body" data-custom-id="map-details" >
                      <div>
                        <div id="map" />
                      </div>
                      <div class="row">
                        <div class="col-md-2"><div class="form-group field-ticketform-longitude">
                          <label class="control-label" data-custom-for="ticketform-longitude">Longitude</label>
                          <input type="text" data-custom-id="ticketform-longitude" class="form-control" v-model="lon" @change="setLatitude">
                          <p class="help-block help-block-error"></p>
                        </div></div>
                        <div class="col-md-2"> <div class="form-group field-ticketform-latitude">
                          <label class="control-label" data-custom-for="ticketform-latitude">Latitude</label>
                          <input type="text" data-custom-id="ticketform-latitude" class="form-control" v-model="lat" @change="setLongitude">

                          <p class="help-block help-block-error"></p>
                        </div></div>

                        <div class="col-md-8"> <div class="form-group field-ticketform-address">
                          <label class="control-label" data-custom-for="ticketform-address">Address</label>
                          <div class="input-group">
                            <a class="input-group-text" @click="searchAdressOnMap">
                              <i class="fas fa-map-marker-alt fa-lg" aria-hidden="true"></i>
                            </a>
                            <input type="text" data-custom-id="ticketform-address" class="form-control" v-model="addressFromMap" @change="setAddress">
                          </div>
                          <p class="help-block help-block-error"></p>
                        </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr/>
                  <div class="panel-info">
                    <div class="row"></div>
                    <div class="panel-body" data-custom-id="ticket-details">
                      <div class="row">
                        <div class="col-md-4">

                          <div class="form-group field-ticketform-dept_id">
                            <label class="control-label" data-custom-for="ticketform-dept_id">Department</label>
                            <Multiselect
                                v-model='departmentId'
                                placeholder='Select department...'
                                :searchable='true'
                                label='name'
                                valueProp='id'
                                trackBy='name'
                                :options='departments'
                            >
                            </Multiselect>
                            <p class="help-block help-block-error"></p>
                          </div>

                          <div class="form-group field-ticketform-team_id">
                            <label class="control-label" data-custom-for="ticketform-team_id">Team</label>
                            <Multiselect
                                v-model='teamId'
                                mode="tags"
                                placeholder='Select team(s)...'
                                label='name'
                                trackBy="name"
                                valueProp="id"
                                :searchable='true'
                                :options='teams'
                            >
                            </Multiselect>
                            <p class="help-block help-block-error"></p>
                          </div>

                          <div class="form-group field-ticketform-assigned_to">
                            <label class="control-label" data-custom-for="ticketform-team_id">Assigned Co-Worker(s)</label>
                            <Multiselect
                                v-model='coWorkers'
                                mode='tags'
                                placeholder='Choose co-worker(s)...'
                                trackBy='name'
                                valueProp='id'
                                label='name'
                                :searchable='true'
                                :options='workers'
                            >
                              <template v-slot:tag="{ option, remove, disabled }">
                                <div class="multiselect-tag is-user">
                                  <img class="user-avatar" :src="option.avatarLink">
                                  {{ option.name }}
                                  <i
                                      v-if="!disabled"
                                      @click.prevent
                                      @mousedown.prevent.stop="remove(option)"
                                  />
                                </div>
                              </template>
                              <template v-slot:option="{ option }">
                                <img class="user-avatar" :src="option.avatarLink"> {{ option.name }}
                              </template>
                            </Multiselect>
                            <p class="help-block help-block-error"></p>
                          </div>

                          <div class="form-group field-ticketform-responsible_user">
                            <label class="control-label" data-custom-for="ticketform-responsible_user">Responsible worker</label>
                            <Multiselect
                                v-model='responsibleUser'
                                placeholder='Choose major worker...'
                                trackBy='name'
                                valueProp='id'
                                label='name'
                                :searchable='true'
                                :options='workers'
                            >
                              <template v-slot:singlelabel="{ value }">
                                <div class="multiselect-single-label">
                                  <img class="user-avatar" :src="value.avatarLink"> {{ value.name }}
                                </div>
                              </template>

                              <template v-slot:option="{ option }">
                                <img class="user-avatar" :src="option.avatarLink"> {{ option.name }}
                              </template>
                            </Multiselect>

                            <p class="help-block help-block-error"></p>
                          </div>
                        </div>

                        <div class="col-md-4">
                          <div class="row">
                            <div class="form-group field-ticketform-sell_price col-md-6">
                              <label class="control-label" data-custom-for="ticketform-sell_price">Price for Buyer, HC</label>
                              <input type="text" data-custom-id="ticketform-sell_price" class="form-control" v-model="sellPrice">
                              <p class="help-block help-block-error"></p>
                            </div>
                            <div class="form-group field-ticketform-price col-md-6">
                              <label class="control-label" data-custom-for="ticketform-price">Price for Team, HC</label>
                              <input type="text" data-custom-id="ticketform-price" class="form-control" v-model="price" required>
                              <p class="help-block help-block-error"></p>
                            </div>
                          </div>

                          <div class="form-group field-ticketform-for_whome">
                            <label class="control-label" data-custom-for="ticketform-for_whome">For Whome (for invoice)</label>
                            <Multiselect
                                v-model='forWhome'
                                placeholder='Choose customer...'
                                trackBy='name'
                                valueProp='id'
                                label='name'
                                :searchable='true'
                                :options='[{ name:"Guest", id:"guest",},{ name:"Owner", id:"owner",},{ name:"Internal", id:"internal",}]'
                            >
                            </Multiselect>
                            <p class="help-block help-block-error"></p>
                          </div>

                          <div class="row">
                            <div class="form-group field-ticketform-estimated_time col-md-6">
                              <label class="control-label" data-custom-for="ticketform-estimated_time">Estimated time, min</label>
                              <input type="text" data-custom-id="ticketform-estimated_time" class="form-control" v-model="estimatedTime">
                              <p class="help-block help-block-error"></p>
                            </div>

                            <div class="form-group field-ticketform-status_id col-md-6">
                              <label class="control-label" data-custom-for="ticketform-status_id">Current status</label>
                              <Multiselect
                                  v-model='statusId'
                                  placeholder='Select status...'
                                  label='name'
                                  valueProp="id"
                                  trackBy='name'
                                  :searchable='true'
                                  :options='statuses'
                              >
                              </Multiselect>
                              <p class="help-block help-block-error"></p>
                            </div>

                            <div class="form-group field-ticketform-priority_id">
                              <label class="control-label" data-custom-for="ticketform-priority_id">Priority</label>
                              <Multiselect
                                  v-model='priorityId'
                                  placeholder='Select priority...'
                                  label='name'
                                  trackBy='name'
                                  :searchable='true'
                                  :options='priorities'
                              >
                              </Multiselect>
                              <p class="help-block help-block-error"></p>
                            </div>
                          </div>
                        </div>

                        <div class="col-md-4">
                          <div class="col-md-12">
                            <label class="control-label" data-custom-for="ticketform-address">Upload files (photos)</label>
                            <img src="" width="300" height="230" style="border: 1px solid #cccccc">
                          </div>
                          <div class="form-group col-md-12">
                            <div class="" style="margin-top: 10px;">
                              <label class="custom-file-input btn btn-warning" for="files" />
                              <input id="files" type="file" multiple="multiple" name="_photos"  ref="files" accept="image/*" style="visibility: hidden;" v-on:change="handleFileUploads">
                            </div>
                          </div>
                        </div>

                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="footer-form">
              <div class="row">
                <div class="col-lg-12">
<!--                  <button type="button" class="btn btn-danger" @click="" >&#10007; Cancel</button>-->
                  <input type="reset" value="&#x2704; Clear form" class="btn btn-warning" style="margin-right:10px">
                  <input type="button" class="btn btn-primary" value="&#10001; Update"  @click="updateTicket">
                  <!--                  <input type="submit" class="btn btn-primary" v-if="id === null || id === undefined" value="&#10011; Add new Ticket" >-->
                  <!--                  <input type="submit" class="btn btn-primary" v-else value="&#10001; Update">-->
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import Chat from "./Chat";
import Worker from "./Worker";
import {ActionTypes} from "@/store/actions";
import {useStore} from "@/store";
import {computed, onMounted, reactive, ref, toRefs} from "vue";
import Multiselect from '@vueform/multiselect'
import flatPickr from "vue-flatpickr-component";
import 'flatpickr/dist/flatpickr.css';
import 'flatpickr/dist/themes/material_blue.css';
import dateService from "@/services/dates.service";
import router from "@/router";
import ticketsService from "@/services/tickets.service";


export default {
  name: "Ticket View",
  setup(props) {
    const store = useStore();
    const state = reactive ({
      id: "",
      title: "",
      description:"",
      teamId: [],
      body: "",
      dateRange: "",
      helpTopicId: "",
      number: "",
      topic: "",
      status: "",
      statusId: "",
      team: "",
      department: "",
      departmentId: "",
      source: "",
      priority: "",
      priorityColor: "",
      date: "",
      author: "",
      assignedTo: [],
      internalTickets: "",
      created: "",
      updated: "",
      dueDate: "",
      threads: "",
      parent: "",
      apartmentId: "",
      apartmentName: "",
      arrivalRentId: "",
      departureRentId: "",
      doubleBedSet: "",
      singleBedSet: "",
      kidBedSet: "",
      startDate: "",
      towellSets: "",
      bookingEntity: "",
      gifts: "",
      bedsSet: "",
      price: "",
      sellPrice: "",
      startPerformanceDate: "",
      passcodes: "",
      duration: "",
      isOnPause: "",
      highLight: "",
      listKeys: "",
      toUserUp: "",
      toUserDown: "",
      longitude: "",
      latitude: "",
      address: "",
      invoices: "",
      sla: "",
      countOfWorkers: "",
      responsibleUser: "",
      timePerWorker: "",
      forWhome: "",
      estimatedTime: "",
      major: "",
      coWorkers: [],
      deptId: "",
      priorityId: "",
      // addExternalCustomerForm: "",
      addMapsOptions: "",
      addAdvancedOptions: "",
      myClass: "",
      mySecondClass: "",
      isMapActive: "",
      backcolor: "",
      customerName: "",
      customerOIB: "",
      customerAddress: "",
      lat :"",
      lon: "",
      addressFromMap: "",
    })
    // const   configCalendar = reactive ({
    const   configCalendar =  ({
      enableTime: true,
      mode: "range",
      dateFormat: "d.m.Y H:i",
      time24hr: true,
      minTime: "8:00",
      maxTime: "22:00",
      wrap: true,
      defaultDate: [],
      dateRange: ""
    })
    const user = computed(() => store.getters.getUser);
    const workers = computed(() => store.getters.resources);
    const departments = computed(() => store.getters.departments);
    const priorities = computed(() => store.getters.prioritiesOptions);
    const topics = computed(() => store.getters.topics);
    const modalOpened = computed(() => store.getters.modalOpened);
    const resources = computed(() => store.getters.resources);
    const apartments = computed(() => store.getters.apartments);
    const statuses = computed(() => store.getters.statuses);
    const teams = computed(() => store.getters.teams);

    const getTicketById = computed(()=> store.getters.getTicketById(Number(props.id)))
    const updateTicket = async () => {
      console.log("updating ticket");
      await store.dispatch(ActionTypes.saveTicket, {
        id: state.id,
        dept_id: state.departmentId ,
        apartment_id: state.apartmentId ,
        team_id: state.teamId ,
        priority_id: state.priorityId ,
        sla: state.sla ,
        help_topic_id: state.helpTopicId ,
        status_id: state.statusId ,
        assigned_to: state.coWorkers ,
        duedate: dateService.getFromUnixTimestamp(state.dateRange) ,
        startdate: dateService.getToUnixTimestamp(state.dateRange) ,
        dateRange: state.dateRange ,
        price: state.price ,
        sell_price: state.sellPrice ,
        estimated_time: state.estimatedTime ,
        highLight: state.highLight ,
        responsible_user: state.responsibleUser ,
        for_whome: state.forWhome ,
        address: state.address ,
        longitude: state.lon ,
        latitude: state.lat ,
        title: state.title ,
        body: state.body ,
        description: state.description
      } ).then(
          ()=>{
        router.push('/tickets');
      });

    }

    const fetchPrices = ()=> {
      console.log("fetch")
      if (state.apartmentId !== "" && state.helpTopicId !== "") {
        ticketsService.getSettings(state.apartmentId, state.helpTopicId).then((response) => {
          if (response.data.success) {
            state.price = response.data.price;
            state.departmentId = response.data.dept_id;
            state.title = response.data.title;
            state.description = response.data.description;
            state.body = response.data.description;
            state.priorityId = response.data.priority_id;
            state.statusId = response.data.status_id;
            state.slaId = response.data.sla_id;
            while (state.teamId.length) {
              state.teamId.pop();
            }
            state.teamId.push(response.data.team_id);
            while (state.coWorkers.length) {
              state.coWorkers.pop();
            }
            debugger
            response.data.assigned_to.forEach((element) => {
              state.coWorkers.push(element);
            })
            state.responsibleUser = response.data.responsible_user;
            state.sellPrice = response.data.sell_price;
            state.estimatedTime = response.data.estimated_time;
          } else {
            state.price = 0;
            state.departmentId = "";
            state.priorityId = "";
            state.statusId = "";
            state.slaId = "";
            while (state.assignedTo.length) {
              state.assignedTo.pop();
            }
            while (state.teamId.length) {
              state.teamId.pop();
            }
            state.responsibleUser = "";
            state.sellPrice = 0;
            state.estimatedTime = 0;
          }
        })
      }
    }

    const setFields = () => {
      const ticket = store.getters.getTicketById(Number(props.id))
      if (ticket) {
        debugger
        const from = new Date(ticket.startDate);
        const to = new Date(ticket.dueDate);
        const dateRange = dateService.getRangeStringFromToWithTo([from.toISOString(),to.toISOString()]);
          configCalendar.defaultDate.push (from)
          configCalendar.defaultDate.push (to)

        state.id = ticket.id
        state.title = ticket.title
        state.number = ticket.number
        state.lat = ticket.latitude
        state.lon = ticket.longitude
        state.addressFromMap = ticket.address
        state.description=ticket.description
        state.teamId=ticket.teamId
        state.body= ticket.body
        state.dateRange= dateRange //"20.01.2021 10:00"+" to "+"20.02.2021 12:00"
        state.helpTopicId= ticket.helpTopicId
        state.number= ticket.number
        state.topic= ticket.topic
        state.status= ticket.status
        state.statusId= ticket.statusId
        state.team= ticket.team
        state.department= ticket.department
        state.departmentId= ticket.departmentId
        state.source= ticket.source
        state.priority= ticket.priority
        state.priorityColor= ticket.priorityColor
        state.date= ticket.date
        state.author= ticket.author
        // state.assignedTo= ticket.assignedTo
        state.internalTickets= ticket.internalTickets
        state.created= ticket.created
        state.updated= ticket.updated
        state.dueDate= ticket.dueDate
        state.threads= ticket.threads
        state.parent= ticket.parent
        state.apartmentId= ticket.apartmentId
        state.apartmentName= ticket.apartmentName
        state.arrivalRentId= ticket.arrivalRentId
        state.departureRentId= ticket.departureRentId
        state.doubleBedSet= ticket.doubleBedSet
        state.singleBedSet= ticket.singleBedSet
        state.kidBedSet= ticket.kidBedSet
        state.startDate= ticket.startDate
        state.towellSets= ticket.towellSets
        state.bookingEntity= ticket.bookingEntity
        state.gifts= ticket.gifts
        state.bedsSet= ticket.bedsSet
        state.price= ticket.price
        state.sellPrice= ticket.sell_price
        state.startPerformanceDate= ticket.startPerformanceDate
        state.passcodes= ticket.passcodes
        state.duration= ticket.duration
        state.isOnPause= ticket.isOnPause
        state.highLight= ticket.highLight
        state.listKeys= ticket.listKeys
        state.toUserUp= ticket.toUserUp
        state.toUserDown= ticket.toUserDown
        // state.longitude= ticket.longitude
        // state.latitude= ticket.latitude
        state.address= ticket.address
        state.invoices= ticket.invoices
        state.sla= ticket.sla
        state.countOfWorkers= ticket.countOfWorkers
        state.responsibleUser= ticket.major.id
        state.timePerWorker= ticket.timePerWorker
        state.forWhome= ticket.forWhome
        state.estimatedTime= ticket.estimatedTime
        state.major= ticket.major
        // state.coWorkers=
            ticket.coWorkers.forEach((coWorker)=>{state.coWorkers.push(coWorker.id)})
        state.deptId= ticket.departmentId
        state.priorityId= ticket.priorityId
        // state.addExternalCustomerForm= ticket.addExternalCustomerForm
        state.addMapsOptions= ticket.addMapsOptions
        state.addAdvancedOptions= ticket.addAdvancedOptions
        state.myClass= ticket.myClass
        state.mySecondClass= ticket.mySecondClassets
        state.isMapActive= ticket.isMapActive
        state.backcolor= ticket.backcolor
        state.customerName= ticket.customerName
        state.customerOIB= ticket.customerOIB
        state.customerAddress= ticket.customerAddress

      }
    }

    onMounted(() => {
      store.dispatch(ActionTypes.fetchApartments,null);
      store.dispatch(ActionTypes.fetchTeams,null);
      setFields()
      // if (ticket.value.status==="Processing") {
      //   // timer.value = ticket.value.duration
      //   startTimer()
      // }
    });
    return {
      user,
      workers,
      departments,
      priorities,
      topics,
      modalOpened,
      resources,
      apartments,
      statuses,
      teams,
      fetchPrices,
      updateTicket,
      configCalendar,
      // addExternalCustomer,
      ...toRefs(state),
      // ...toRefs(configCalendar)
    };

  },
  components: {Multiselect, flatPickr },

  props: {
    id: {
      type: Number,
      required: true
    },
  },
  methods: {

    add() {
      console.log('add')
    },
    replace() {
      console.log('replace')
    },
    checkMove(event) {
      console.log('checkMove', event)
    },
    log(event) {
      const { moved, added } = event
      if (moved) console.log('moved', moved)
      if (added) console.log('added', added, added.element)
    },
    //  moveUp(id)  {
    //
    //   this.store.dispatch(ActionTypes.moveUp, id);
    // },
  // //   start() {
  // //     this.ticket.value.status = "Processing";
  // //     this.$emit('ticketStart', this.ticket.value.id);
  // //     this.processing=true;
  // //     this.ticketClass="Processing";
  // //     // this.ticketStyle.backgroundColor="orange";
  // //     console.log("status changed to processing");
  // //   },
  // //   finish() {
  // //     this.ticket.value.status = "Finished";
  // //     this.$emit('ticketFinish', this.ticket.value.id);
  // //     this.ticketStyle.backgroundColor="pink";
  // //     // this.ticketStyle.opacity=0.5;
  // //   },
  // //   pause() {
  // //     //
  // //     this.ticket.value.status = "OnPause";
  // //     this.image = "./logo.png";
  // //     this.$emit('ticketPause', this.ticket.value.id);
  // //     this.processing=false; console.log("status changed to paused");
  // //     this.ticketStyle.backgroundColor="red";
  // //   },
  // //   reopen() {
  // //     console.log("reopen")
  // //   },
  // //   closeTicket() {
  // //     console.log("closeTicket")
  // //   },
  // //   assignUpdate() {
  // //     console.log("assignUpdate")
  // //   },
  // //   invoice() {
  // //     console.log("invoice")
  // //   },
  // //   viewTicket() {
  // //     console.log("viewTicket")
  // //   },
  // //   moveTicketUp() {
  // //     console.log("moveTicketUp")
  // //   },
  // //   moveTicketDown() {
  // //     console.log("moveTicketDown")
  // //   },
  //   isButtonDisable(name){
  //     switch(name) {
  //       case 'reopen': return this.ticket.value.status !== "Finished";
  //       case 'closeTicket': return this.ticket.value.status === "Closed";
  //       case 'start': return this.ticket.value.status === "Processing" || this.ticket.value.status === "Finished" || this.ticket.value.status === "Closed";
  //       case 'finish': return this.ticket.value.status !== "Processing" || this.ticket.value.status === "Finished";
  //       case 'assignUpdate': return this.ticket.value.status === "Processing" || this.ticket.value.status === "Finished" || this.ticket.value.status === "Closed";
  //       default: return true;
  //     }
  //   }
  },

}
</script>

<style lang="scss" scoped>
.actions-icon {
  font-size: 15px; color: #01818C; margin-right: 8px;
  cursor: pointer;
}

.disabledButton {
  opacity: 0.3;
  cursor: not-allowed;
}
.closed {

  opacity: 0.5; /* Полупрозрачность элемента */
}
.deleted {
  /*background-color: #EFF6F7;*/
  text-decoration: line-through;
  opacity: 0.3; /* Полупрозрачность элемента */
}
.processing {
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(3, 128, 140, 0.6);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(3, 128, 140, 0.6);
  border: 0!important;
}
.ticket-on-processing {
  background-color:   #ddecee;
  opacity:   1;
}
.ticket-on-pause {
  background-color:  #e6e6e6;
  opacity:   0.8;
}
.ticket-stop {
  background-color:  whitesmoke;
  opacity:   1;
}
.ticket-finished {
  background-color:  	#fdf4e8;
  opacity:   0.8;
}
.ticket-closed {
  background-color:  	 #999999;
  opacity:   0.5;
}
.out_of_schedule {
  background-color: red;
  opacity: 0.8;
}
.tc-note {
  //background-color: #f0c806;
  border-radius: 8px;
  width: 100%;
  margin: 0 0 1px;
  box-shadow: 1px 3px 5px rgba(0, 0, 0, 0.2);
  transition: all 0.5s;
  cursor: pointer;
  font-family: 'Caveat', cursive;

  .tc-note-header {
    padding: 10px 16px 0;

    .tc-note-close {
      display: inline-block;
      width: 24px;
      height: 24px;
      border-radius: 50%;
      line-height: 24px;
      text-align: center;
      transition: all 0.3s;

      &:hover {
        background-color: rgba(0, 0, 0, 0.2);
      }

      &:focus {
        box-shadow: inset 2px 3px 0px rgba(0, 0, 0, 0.8);
      }
    }

    .tc-note-close {
      float: right;
    }
  }

  .tc-note-title,
  .tc-note-body {
    outline: 0;
  }

  .tc-note-title {
    font-size: 24px;
    padding: 10px 16px;
    font-weight: bold;
  }

  .tc-note-body {
    //font-size: 20px;
    padding: 5px 0;
  }

  &:hover {
    box-shadow: 2px 4px 10px rgba(0, 0, 0, 0.3);
  }
}
</style>
